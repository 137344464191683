export default [
    {
        document_type: "CL33",
        total: 100,
        id: "caf_IgSKs64z4pgJXOO4LF",
        available_to_give: true,
        data: "PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxBVVRPUklaQUNJT04+CjxDQUYgdmVyc2lvbj0iMS4wIj4KPERBPgo8UkU+Nzc1MjA5OTctNjwvUkU+CjxSUz5BWFRFUk9JRCBTUEE8L1JTPgo8VEQ+MzM8L1REPgo8Uk5HPjxEPjE8L0Q+PEg+MTAwPC9IPjwvUk5HPgo8RkE+MjAyMi0wMy0xODwvRkE+CjxSU0FQSz48TT55QXBZNHpoRHNhcDZITklVcXN2RGxXSSs5YjU4dkZKNXNkMGtRcW1oLzZMUmRxRHk2OTNTbXg2cW5ZbjFINXp4MjI4VUd2bmRuTU16c0E0SXU0V3plUT09PC9NPjxFPkF3PT08L0U+PC9SU0FQSz4KPElESz4xMDA8L0lESz4KPC9EQT4KPEZSTUEgYWxnb3JpdG1vPSJTSEExd2l0aFJTQSI+a3BkektaczV1MzRGSjcxZGlIYkl5UWgvL1lNdkF1d0svMy95QWtUWFJZTEVTaFVCMEhKaUFkbFFJR29ib2FjREorRVlDOWdXN2JOdjc2Z1RjbkRTK1E9PTwvRlJNQT4KPC9DQUY+CjxSU0FTSz4tLS0tLUJFR0lOIFJTQSBQUklWQVRFIEtFWS0tLS0tCk1JSUJPZ0lCQUFKQkFNZ0tXT000UTdHcWVoelNGS3JMdzVWaVB2VytmTHhTZWJIZEpFS3BvZitpMFhhZzh1dmQKMHBzZXFwMko5UitjOGR0dkZCcjUzWnpETTdBT0NMdUZzM2tDQVFNQ1FRQ0ZYRHRDSllKMmNhYTk0V01jaDllNAo3Q241S2FoOWpGRWhQaGdzY1JhcWExMFNvT3lrZGdRdjgrSS9FNjZ3dkp1WFBMYkxZenlEZEZtbHRFVkVpZnNMCkFpRUE4OU55VmtzdzZ0M2ZmTHVlaGtqR21OVTI3aDlEWUF5NTM2WjJlbVVsMDFVQ0lRRFNCejA1cWZ2aGRWRmEKZzAzb3pidHZvMTBUeXFHaXl0ck5rUWttYjVEbmxRSWhBS0tNOXVReUlKeVQ2bE1udndRd2hHWGplZlFVMTVWZAowVC9FVHZ4RGJvempBaUVBakFUVGU4YW42Nk9Ma2F6ZW16UFNTbXpvdDl4cndkeVIzbUN3eEVwZ21tTUNJR25lCjJ6dVhPTml6cUZzUk52bUoyM09YanlpN3dmUU9hVEhKOGFrdjM3NkUKLS0tLS1FTkQgUlNBIFBSSVZBVEUgS0VZLS0tLS0KPC9SU0FTSz4KCjxSU0FQVUJLPi0tLS0tQkVHSU4gUFVCTElDIEtFWS0tLS0tCk1Gb3dEUVlKS29aSWh2Y05BUUVCQlFBRFNRQXdSZ0pCQU1nS1dPTTRRN0dxZWh6U0ZLckx3NVZpUHZXK2ZMeFMKZWJIZEpFS3BvZitpMFhhZzh1dmQwcHNlcXAySjlSK2M4ZHR2RkJyNTNaekRNN0FPQ0x1RnMza0NBUU09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo8L1JTQVBVQks+CjwvQVVUT1JJWkFDSU9OPgoK",
        from_number: 1,
        is_pos: false,
        life_from: "2022-03-18",
        life_to: "2022-09-18",
        test_mode: false,
        to_number: 100,
        auto: false,
        created: "2023-08-17T09:36:19.474284-04:00",
        updated: "2023-08-17T09:36:19.474306-04:00",
        user: 25,
        used: 510.826,
        availables: 33641263,
        status: {
            code: 'Critico',
            percent: 80
        }
    },
    {
        document_type: "CL34",
        total: 100,
        id: "caf_YBmBpVG6WFOrJdwHRg",
        available_to_give: false,
        data: "PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxBVVRPUklaQUNJT04+CjxDQUYgdmVyc2lvbj0iMS4wIj4KPERBPgo8UkU+Nzc1MjA5OTctNjwvUkU+CjxSUz5BWFRFUk9JRCBTUEE8L1JTPgo8VEQ+MzQ8L1REPgo8Uk5HPjxEPjE8L0Q+PEg+MTAwPC9IPjwvUk5HPgo8RkE+MjAyMi0wMy0yMTwvRkE+CjxSU0FQSz48TT5xN0lsaHd1ekNMN3JTL0gyZUFnazREYk9Ba3RFejM3WnNwaHUyd2ZndVpoaXBaenFlTDhudDZqSjNJR0dYbmNMREdia01RYkd3V2M1akhDd2d0SGhOdz09PC9NPjxFPkF3PT08L0U+PC9SU0FQSz4KPElESz4xMDA8L0lESz4KPC9EQT4KPEZSTUEgYWxnb3JpdG1vPSJTSEExd2l0aFJTQSI+T3UxMUsydFhEdEdvRDdGYVpnK0cvbk1BS2N4cnA0MGh6UW8xY0lGdXpwcHc5TlY4dU1FY0dxQ3JVU2x4aFJ4SFZxOGdqTHdxUHhPRUpsb0NpRSs4QXc9PTwvRlJNQT4KPC9DQUY+CjxSU0FTSz4tLS0tLUJFR0lOIFJTQSBQUklWQVRFIEtFWS0tLS0tCk1JSUJPUUlCQUFKQkFLdXlKWWNMc3dpKzYwdng5bmdJSk9BMnpnSkxSTTkrMmJLWWJ0c0g0TG1ZWXFXYzZuaS8KSjdlb3lkeUJobDUzQ3d4bTVERUd4c0ZuT1l4d3NJTFI0VGNDQVFNQ1FISjJ3NjlkSWdYVW5OMUwrYVZhdzBBawppVmJjMklwVWtTRzY5SklGUUh1NTFPSUhaR00vUjJIaWxncGQzSWdYcXprUU9XNTBzMWxPUlM0c2NtOEE4SnNDCklRRFRsY2ZpcWVlRWM3dVl3VXAydEhSODg4dTBZYWpZVnNDbC9LLy8yS0pYcXdJaEFNKzh5ZkU1K0xneEdWQUwKcWtUZDN3MURBdG1wcnVGa3NTdktmZ1VEcmlDbEFpRUFqUTZGUWNhYVdFMG51eXVHK2MyaS9mZmRJdXZGNVk4cgpHVk1mLytYQmo4Y0NJUUNLZmR2MkpxWFFJTHVLc25HRFBwU3pnZ0htY1I5QTdjdHlodjZ1QW5RVnd3SWdJL2ozCms2Uks0c3ZVVWJWU3NIdXFUbkJsaHhJMTMrVmdCb2l4U2RKZTU5RT0KLS0tLS1FTkQgUlNBIFBSSVZBVEUgS0VZLS0tLS0KPC9SU0FTSz4KCjxSU0FQVUJLPi0tLS0tQkVHSU4gUFVCTElDIEtFWS0tLS0tCk1Gb3dEUVlKS29aSWh2Y05BUUVCQlFBRFNRQXdSZ0pCQUt1eUpZY0xzd2krNjB2eDluZ0lKT0EyemdKTFJNOSsKMmJLWWJ0c0g0TG1ZWXFXYzZuaS9KN2VveWR5QmhsNTNDd3htNURFR3hzRm5PWXh3c0lMUjRUY0NBUU09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo8L1JTQVBVQks+CjwvQVVUT1JJWkFDSU9OPgo=",
        from_number: 1,
        is_pos: false,
        life_from: "2022-03-21",
        life_to: "2022-09-21",
        test_mode: false,
        to_number: 100,
        auto: false,
        created: "2023-07-05T12:48:48.974683-04:00",
        updated: "2023-07-05T12:48:48.974703-04:00",
        user: 5,
        used: 41989,
        availables: 809441,
        status: {
            code: 'Critico',
            percent: 80
        }
    },
    {
        document_type: "CL56",
        total: 100,
        id: "caf_p3EtalJvJwH5AXAKQ7",
        available_to_give: false,
        data: "PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxBVVRPUklaQUNJT04+CjxDQUYgdmVyc2lvbj0iMS4wIj4KPERBPgo8UkU+Nzc1MjA5OTctNjwvUkU+CjxSUz5BWFRFUk9JRCBTUEE8L1JTPgo8VEQ+NTY8L1REPgo8Uk5HPjxEPjE8L0Q+PEg+MTAwPC9IPjwvUk5HPgo8RkE+MjAyMi0wMy0yMTwvRkE+CjxSU0FQSz48TT5xMW50ak55QkhsVU92TUwrYitVek5DWFVPbnVzS2lHclJzRTl3VkF5OWQzdktiZlVOWVk0NjFoRzkwZkN1eXlHakdNMXYvVzluMVJTUTJMRm1Dc3Iydz09PC9NPjxFPkF3PT08L0U+PC9SU0FQSz4KPElESz4xMDA8L0lESz4KPC9EQT4KPEZSTUEgYWxnb3JpdG1vPSJTSEExd2l0aFJTQSI+ZDJKL2FhR1hoYk1odVZyaEd4MGZibVBKOWpkZ1Q1dXhIMnd5bzN2TFZ1NWxYY1ZiZmdmSjhkYVpqTU1zZFdUK28zaGUwd3Z5dUdaaEE2YkJRWUVNb2c9PTwvRlJNQT4KPC9DQUY+CjxSU0FTSz4tLS0tLUJFR0lOIFJTQSBQUklWQVRFIEtFWS0tLS0tCk1JSUJPUUlCQUFKQkFLdFo3WXpjZ1I1VkRyekMvbS9sTXpRbDFEcDdyQ29ocTBiQlBjRlFNdlhkN3ltMzFEV0cKT090WVJ2ZEh3cnNzaG94ak5iLzF2WjlVVWtOaXhaZ3JLOXNDQVFNQ1FISTc4N005cTJtT0NkTXNxWi91SWlMRAo0dEdueUJ3V2NpOHJmb0RnSWZrOWg1QStLMzk4VE9WSCs0a3NWK1c5cUV1djlLL2NyaktkaUZUZElnemx0aXNDCklRRGY1djVSQ1JxOXNpbkUzQ0FKK09DWk4rYlFmMEpmUkVZU0trVHFnWWhWZXdJaEFNUHFYRUh0TVFmaFFvak4KWlRUcHIzRGk5SFk0NkZrUElmT1owaWdEU2tVaEFpRUFsVVNwaTF0bktTRnhMZWdWVy90QVppVkVpditCbE5ndQp0c2JZbkZaYTQ2Y0NJUUNDbkQyQlNNdGFsaXhiTTVqTjhSK2dsMDJrSmZBN1gydjN1K0Z3QWpHRGF3SWdRVDJpCk9HT1JyVGd5QjRJRjE1bjA1UjNrNFJUcDJpRkRtdjU5bzNMbG0wTT0KLS0tLS1FTkQgUlNBIFBSSVZBVEUgS0VZLS0tLS0KPC9SU0FTSz4KCjxSU0FQVUJLPi0tLS0tQkVHSU4gUFVCTElDIEtFWS0tLS0tCk1Gb3dEUVlKS29aSWh2Y05BUUVCQlFBRFNRQXdSZ0pCQUt0WjdZemNnUjVWRHJ6Qy9tL2xNelFsMURwN3JDb2gKcTBiQlBjRlFNdlhkN3ltMzFEV0dPT3RZUnZkSHdyc3Nob3hqTmIvMXZaOVVVa05peFpncks5c0NBUU09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo8L1JTQVBVQks+CjwvQVVUT1JJWkFDSU9OPgo=",
        from_number: 1,
        is_pos: false,
        life_from: "2022-03-21",
        life_to: "2022-09-21",
        test_mode: false,
        to_number: 100,
        auto: false,
        created: "2023-07-05T12:49:01.127342-04:00",
        updated: "2023-07-05T12:49:01.127364-04:00",
        user: 5,
        used: 774217,
        availables: 33839364,
        status: {
            code: 'Medio',
            percent: 73
        }
    },
    {
        document_type: "CL61",
        total: 100,
        id: "caf_d2FM2TN8OUHgBWnNPU",
        available_to_give: false,
        data: "PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxBVVRPUklaQUNJT04+CjxDQUYgdmVyc2lvbj0iMS4wIj4KPERBPgo8UkU+Nzc1MjA5OTctNjwvUkU+CjxSUz5BWFRFUk9JRCBTUEE8L1JTPgo8VEQ+NjE8L1REPgo8Uk5HPjxEPjE8L0Q+PEg+MTAwPC9IPjwvUk5HPgo8RkE+MjAyMi0wMy0yMTwvRkE+CjxSU0FQSz48TT5wSnZ3TFRST3k1NTFuRFVzdEVCSkpUWnlhWDdPWWUwY0VYNEpIbU85OGlmbkZWa2hwZlNIK0FIeFpwUldGU3F6dTVUNG9pemFld0I2ZnN3Nzc5ZWRSdz09PC9NPjxFPkF3PT08L0U+PC9SU0FQSz4KPElESz4xMDA8L0lESz4KPC9EQT4KPEZSTUEgYWxnb3JpdG1vPSJTSEExd2l0aFJTQSI+ZjFBYkpaZ1pzb3RvKytGak1NZXFHcDc1a0R6aC81eDNBNHArUzF2Nzg2cmR3ZVdIOGtKbEh6OFpJSEVQcnRRdFNNSnlPRFlDZlpOT2NKdk5XVzFUN2c9PTwvRlJNQT4KPC9DQUY+CjxSU0FTSz4tLS0tLUJFR0lOIFJTQSBQUklWQVRFIEtFWS0tLS0tCk1JSUJPZ0lCQUFKQkFLU2I4QzAwVHN1ZWRadzFMTFJBU1NVMmNtbCt6bUh0SEJGK0NSNWp2ZkluNXhWWklhWDAKaC9nQjhXYVVWaFVxczd1VStLSXMybnNBZW43TU8rL1huVWNDQVFNQ1FHMjlTc2pOaWQwVVRtZ2pjeUxWaGhqTwo5dkQvTkVGSXZXRCtzTDd0S1V3WmlFaXljcGtnWDB6ajMwTUxCWUxKNDE0OHptQ08zTm13ZU9YbkliTGVwS3NDCklRRFJ5MlczN003ZUllS2ZnWmQ0TytPc1pzcnR6eXdaalVsaTlFQk9kNFMzeHdJaEFNamM1NzNUZFJyaXlZTUEKYkZXVkdESkhidFZDS25XbkxtSXhzVHJzQk82QkFpRUFpOXp1ZXAzZlBzRkJ2NnU2VUNmdEhabmNub29kWmw0dwo3S0xWaWFVREpTOENJUUNGNkpwK2pQaThsekVDQUVnNURoQWhoUFNPTEJ4T2IzUkJkblluU0FOSnF3SWhBSWxqCk5WUld3S3hOTm81VXZFN0NrV08zS2tHbHcvOHg2cTRLYkN6ODc2YUwKLS0tLS1FTkQgUlNBIFBSSVZBVEUgS0VZLS0tLS0KPC9SU0FTSz4KCjxSU0FQVUJLPi0tLS0tQkVHSU4gUFVCTElDIEtFWS0tLS0tCk1Gb3dEUVlKS29aSWh2Y05BUUVCQlFBRFNRQXdSZ0pCQUtTYjhDMDBUc3VlZFp3MUxMUkFTU1UyY21sK3ptSHQKSEJGK0NSNWp2ZkluNXhWWklhWDBoL2dCOFdhVVZoVXFzN3VVK0tJczJuc0FlbjdNTysvWG5VY0NBUU09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo8L1JTQVBVQks+CjwvQVVUT1JJWkFDSU9OPgo=",
        from_number: 1,
        is_pos: false,
        life_from: "2022-03-21",
        life_to: "2022-09-21",
        test_mode: false,
        to_number: 100,
        auto: false,
        created: "2023-07-05T12:48:55.224902-04:00",
        updated: "2023-07-05T12:48:55.224921-04:00",
        user: 5,
        used: 258484,
        availables: 464659,
        status: {
            code: 'Normal',
            percent: 33
        }
    }
]