<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del folio" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: false, text: nameDocumentsType($route.params.id) }" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="dialogRequestFolio=true" outlined color="secondary"><v-icon left>mdi-file-document-plus</v-icon>Solicitar folios</v-btn>
        <v-btn class="mr-2" @click="dialogConfFolio=true" outlined color="secondary"><v-icon left>mdi-cog</v-icon>Configuración</v-btn>
        <v-query-builder :query-map="filters" model="folios" />
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <!-- <v-empty-state v-if="!cafsList.length" title="No existen folios para mostrar" type="documents" id="folios" :isFree="true" customClass="mt-n8" /> -->
        <template>
          <v-data-table :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}" :headers="headers" :items="cafsList" hide-default-header disable-sort mobile-breakpoint hide-default-footer fixed-header :height="heightTable">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" />
              <v-divider style="position: absolute; z-index: 2;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
            </template>

            <template v-slot:[`item.created`]="{item}">
              <span class="body-1">{{item.created | date}}</span>
            </template>
            <template v-slot:[`item.life_from`]="{item}">
              <span class="body-1">{{item.life_from | date}} - {{item.life_to | date}}</span>
            </template>
            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 mr-n1">{{item.total | number}}</span>
            </template>
            <template v-slot:[`item.from_number`]="{item}">
              <span class="body-1 mr-n1">{{item.from_number}} - {{item.to_number}}</span>
            </template>
            <template v-slot:[`item.available_to_give`]="{item}">
              <v-icon v-if="!item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else x-small color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.available_to`]="{item}">
              <v-icon v-if="item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.auto`]="{item}">
              <v-icon v-if="item.auto" color="primary">mdi-refresh-auto</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="download(item.data)" :ripple="false">
                    <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
        </v-data-table>
        <VMainPagination :count="count" />
        </template>
      </template>
    </v-col>

    <!-- dialog conf folio -->
    <v-dialog v-model="dialogConfFolio" width="932" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat :key="dialogConfFolio===false">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-6 subtitle-2 fontBody--secondary font-weight-semibold">Configuración de folios para {{ nameDocumentsType($route.params.id).toLowerCase() }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerCloseConfigDialog" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5 background">
          <!-- charging auto-->
          <v-card flat>
            <v-card-text class="pa-0">
              <v-list class="px-5 transparent v-form-list" max-width="100%">
                <v-list-item>
                  <v-list-item-title class="body-1 fontBody--text font-weight-bold d-flex align-center">
                    <v-icon color="secondary" size="18" left>mdi-refresh-auto</v-icon>
                    Autorización y carga automática de folios
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="showAuto" hide-details :ripple="false "/>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <template v-if="showAuto">
                <v-divider />
                <v-row class="pa-5" align="center" no-gutters>
                  <span>Cuando el número de folios restantes sea</span>
                  <v-col cols="1" class="pa-0 mx-3">
                    <v-text-field v-model="configFolio.min_available" :error="$v.configFolio.min_available.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                  </v-col>
                  <span>se deben solicitar</span>
                  <v-col cols="1" class="pa-0 mx-3">
                    <v-text-field v-model="configFolio.quantity" :error="$v.configFolio.quantity.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                  </v-col>
                  <span>folios.</span>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <!-- end charging auto -->

          <!-- notification-->
          <v-card flat class="mt-5">
            <v-card-title class="pa-0">
              <v-toolbar flat height="46" class="transparent">
                <v-toolbar-title class="px-4 subtitle-2 fontBody--secondary font-weight-semibold">
                  <v-icon color="secondary" size="18" left>mdi-bell</v-icon>Notificaciones
                </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-list class="transparent v-form-list" max-width="100%">
                <v-list-item class="px-5">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 fontBody--text font-weight-bold d-flex align-center">Disponibilidad de folios</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre la cantidad restante de folios</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="configFolio.available_to_give" hide-details />
                  </v-list-item-action>
                </v-list-item>
                <v-divider class="my-1"/>
                <v-list-item class="px-5">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 fontBody--text font-weight-bold d-flex align-center">Vencimiento de folios</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre el vencimiento de los folios</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="configFolio.date" hide-details />
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end notification -->

          <!-- recipients-->
          <v-card flat class="mt-5">
            <v-card-title class="pa-0">
              <v-toolbar flat height="46" class="transparent">
                <v-toolbar-title class="px-4 subtitle-2 fontBody--secondary font-weight-semibold">
                  <v-icon color="secondary" size="18" left>mdi-account-multiple</v-icon>Destinatarios
                </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 pb-4">
              <v-list-item class="px-5 my-0" three-line>
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle class="body-1">Selecciona a qué usuarios se les enviarán notificaciones sobre los folios de {{configFolio?.document_type?.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-col class="px-5 mt-n7" cols="8">
                <v-autocomplete
                  v-model="configFolio.mails"
                  :error="$v.configFolio.mails.$error"
                  hide-details
                  single-line
                  color="primary"
                  dense
                  outlined
                  :items="membersList"
                  multiple
                  :chips="false"
                  item-text="email"
                  item-value="email"
                  :label="(configFolio.mails || []).length ? '' : 'Selecciona a los usuarios'"
                  :prefix="(configFolio.mails || []).length >= 1 ? `${(configFolio.mails || []).length === 1 ? `${(configFolio.mails || []).length} seleccionado` : `${(configFolio.mails || []).length} seleccionados`}` : ''"
                  no-data-text="No existen usuarios para mostrar"
                >
                  <template v-slot:selection="{ props }">
                    <span v-if="props" style=""></span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-row no-gutters>
                <v-col class="px-5">
                  <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in configFolio.mails" :key="`${m}${i}`" close @click:close="memberRemove(m, 0)" color="lightBlue" small label close-icon="mdi-close">
                    {{m }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- end recipients -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="handlerCloseConfigDialog" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createConfig" :loading="loadingConfig" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog conf folio -->

    <!-- dialog request folio -->
    <v-dialog v-model="dialogRequestFolio" width="870" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Solicitar folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerCloseRequestFolio" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-1 pb-4 px-0">
          <v-row class="px-5" align="center" no-gutters>
            <v-col cols="4" class="mt-7">
              <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :width="250" />
            </v-col>
            <v-col class="pl-2 pr-1">
              <div class="mt-5">
                <span class="d-block mt-3 body-1 fontBody--text font-weight-bold mb-2">Cantidad de folios</span>
                <v-col cols="2" class="pa-0 mt-3">
                  <v-text-field v-model="newRequestFolio.quantity" :error="$v.newRequestFolio.quantity.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                </v-col>
                <span class="d-block mt-6 body-1 fontBody--text font-weight-bold">Uso de folios</span>
                <v-list class="transparent v-form-list" max-width="100%">
                  <v-list-item class="py-0">
                    <v-list-item-title class="body-1 fontDraw--text">
                      Uso exclusivo para integración a través de agente o webservice
                    </v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <v-switch class="ma-0" disabled hide-details />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <span class="d-block mt-3 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
                <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
              </div>
              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-6" :height="58" dense text>
                <div class="d-flex pa-0 my-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Ten en cuenta que la solicitud de folios
                      <span class="font-weight-bold">puede tomar al menos 15 segundos.</span>
                      <span class="d-block">Por favor,
                        <span class="font-weight-bold"> no cierres la ventana hasta que el proceso haya concluido.</span>
                      </span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="handlerCloseRequestFolio" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createRequestCaf" :loading="loadingRequestFolio" :disabled="confirm.toLowerCase() !== 'confirmar'" color="primary">Cargar folios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog request folio -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import folios from '@/data/foliosList'
import members from '@/data/membersList'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import documentTypesList from '@/collections/documentTypes'
import VEmptyState from '@/components/commons/VEmptyState'
import VMainPagination from '@/components/commons/VMainPagination'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    VEmptyState,
    MiniHeader,
    VMainPagination,
    SkeletonList,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    membersList: [],
    cafsList: [],
    confirm: '',
    newRequestFolio: {
      document_type: '',
      quantity: '',
      test_mode: false
    },
    dialogRequestFolio: false,
    loadingRequestFolio: false,
    loadingConfig: false,
    showAuto: true,
    configFolio: {
      document_type: '',
      min_available: null,
      quantity: null,
      mails: []
    },
    dialogConfFolio: false,
    scroll: 0,
    loading: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    headers: [
      { text: 'Fecha', value: 'created', sortable: true, width: 50 },
      // { text: 'Usuario', value: 'email' },
      { text: 'Vigencia', value: 'life_from', align: 'end', sortable: true },
      { text: 'Rango', value: 'from_number', align: 'end' },
      { text: 'Total', value: 'total', align: 'end' },
      { text: 'WS/Agente', value: 'available_to_give', align: 'center' },
      { text: 'Plataforma', value: 'available_to', align: 'center' },
      { text: ' ', value: 'auto', align: 'center' },
      { text: '', value: 'actions', align: 'end', width: 30 }
    ],
    filters: [
    {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'date',
        id: 'life',
        label: 'Fecha de vigencia',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'select',
        id: 'available_to_give',
        label: 'Uso del folio',
        value: null,
        category: 'b',
        choices: [
          {label: 'WS/Agente', value: 'True'},
          {label: 'Plataforma', value: 'False'}
        ]
      }
    ],
    // breadcrumbs: {},
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height <= 1200 && this.cafsList.length >= 16 && this.cafsList.length <= 20) return 'calc(100vh - 210px)'
      if (this.cafsList.length > 20) return 'calc(100vh - 165px)'
      if (this.cafsList.length < 16) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      // cafsList: state => state.irs.cafsList,
      count: state => state.irs.cafsCount
      // membersList: state => state.accounts.membersList
    }),
    breadcrumbs () {
      return { main: ['true', true].includes(this.$route.query.report) ? 'Reporte de folios' : 'Folios', children: [] }
    }
  },
  mounted () {
    if (['true', true].includes(this.$route.query.isAdmin)) this.dialogConfFolio = true
    this.membersList = members
    this.cafsList = folios.filter((item) => item.document_type === this.$route.params.id)
  },
  created () {
    // this.getList()
    // if (this.$helpers.hasPermission(['view_member'])) {
    //   this.$store.dispatch('accounts/LIST', {
    //     resource: 'members',
    //     query: {
    //       page_size: 9999,
    //       is_active: true
    //     }
    //   })
    // }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (query?.isAdmin) delete query.isAdmin
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/cafs',
        query: {
          ...query,
          document_type: this.$route.params.id
        }
      })
      .then(() => {
        // this.setFilterCaf()
      })
      .finally(() => {
        this.loading = false
      })
    },
    setFilterCaf () {
      if (this.cafsList.length) {
        const index = this.filters.findIndex(({id}) => id === 'number')
        /* eslint-disable */
        this.filters[index].choices = this.cafsList.map(({from_number, to_number}) => {
          return {
            label: `${from_number} - ${to_number}`,
            value: `${from_number}-${to_number}`
          }
        })
      }
    },
    download (data = null) {
      if (data) {
        const content = Buffer.from(data, 'base64')
        saveAs(new Blob([content], {type: 'application/xml'}), 'Detalle stock de folios.xml')
        this.$dialog.message.info('La exportación se realizó con éxito')
      } else {
        this.$dialog.message.error('Ha ocurrido un error al descargar el xml')
      }
    },
    memberRemove (item) {
      this.configFolio.mails = [...this.configFolio.mails.filter((m) => m !== item)]
    },
    createConfig () {
      this.$v.configFolio.$touch()
      if (this.$v.configFolio.$invalid) {
        return false
      }

      this.loadingConfig = true

      const payload = {
        min_available: this.configFolio.min_available,
        quantity: this.configFolio.quantity,
        document_type: this.$route.params.id,
        mails: this.configFolio.mails
      }

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/config_auto_caf',
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('Configuración guardada correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loadingConfig = false
        this.handlerCloseConfigDialog()
      })
    },
    handlerCloseConfigDialog () {
      this.dialogConfFolio = false
      this.configFolio = {
        min_available: null,
        quantity: null,
        document_type: '',
        mails: []
      }
      this.$v.configFolio.$reset()
    },
    createRequestCaf () {
      this.$v.newRequestFolio.$touch()
      if (this.$v.newRequestFolio.$invalid) {
        return false
      }

      this.loadingRequestFolio = true

      const payload = {
        test_mode: false,
        quantity: this.newRequestFolio.quantity,
        document_type: this.$route.params.id,
      }

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/request_auto_caf/manual',
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('Solicitud procesada correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loadingRequestFolio = false
        this.handlerCloseRequestFolio()
      })
    },
    handlerCloseRequestFolio () {
      this.confirm = ''
      this.dialogRequestFolio = false
      this.newRequestFolio = {
        quantity: null,
        document_type: '',
        test_mode: false
      }
      this.$v.newRequestFolio.$reset()
    },
  },
  validations: {
    configFolio: {
      mails: {
        required
      },
      quantity: {
        required
      },
      min_available: {
        required
      }
    },
    newRequestFolio: {
      quantity: {
        required
      }
    }
  }
}
</script>